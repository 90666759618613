import * as React from 'react'
import { Box } from '@xstyled/styled-components'
import { Contact, ContactLine } from '../../types'
import { useModal } from '../../providers'
import { PageWrapper } from '../../components/Layout'
import { Heading } from '../../components/Text'
import { Button } from '../../components/Button'
import {
  Wrapper,
  ContactLines,
  ContactLineWrapper,
  ChatWrapper,
} from './styled'
import ChatBox from '../../svg/ChatBox.svg'
import { SEO } from '../../components/SEO'
import { definitely } from '../../utils'

const Chat = () => {
  const launchChat = () => {
    // @ts-ignore
    if (typeof window !== 'undefined' && window?.HubSpotConversations?.widget) {
      // @ts-ignore
      window.HubSpotConversations.widget.open()
    }
  }
  return (
    <Box width={{ xs: '100%', md: 'auto' }}>
      <ChatBox />
      <Heading my={4} level={3}>
        Contact us directly, via Chat
      </Heading>
      <Button onClick={launchChat} width="100%" minWidth="220px">
        Launch Chat
      </Button>
    </Box>
  )
}

interface ContactProps {
  contact: Contact
}
const ContactLineComponent = ({
  contactLine,
}: {
  contactLine: Pick<ContactLine, 'label' | 'contact'>
}) => {
  const { label, contact } = contactLine
  if (!label || !contact) return null
  const href = /@/.test(contact)
    ? `mailto:${contact}`
    : `tel:${contact.replace(/[^0-9\.]+/g, '')}`
  return (
    <ContactLineWrapper>
      <Heading level={4}>{label}</Heading>
      <Heading level={3}>
        <a href={href}>{contact}</a>
      </Heading>
    </ContactLineWrapper>
  )
}

export const ContactView = ({ contact }: ContactProps) => {
  const { seo, title } = contact
  const { openContactModal } = useModal()
  const defaultSeo = {
    title: 'Contact',
  }
  const handleModalClick = (formtype: string) => () =>
    openContactModal({ formtype })

  return (
    <>
      <SEO seo={seo} defaultSeo={defaultSeo} path="about/contact" />
      <PageWrapper>
        <Heading level={1} textAlign="center">
          {title || 'Contact'}
        </Heading>
        <Wrapper>
          <ContactLines>
            {definitely(contact.contactLines).map((cl) => (
              <ContactLineComponent
                key={cl._key || 'some-key'}
                contactLine={cl}
              />
            ))}
            {/* <ContactLineWrapper>
              <Heading level={3}>For order inquiries:</Heading>
              <Button level={2} onClick={handleModalClick('Order')}>
                Contact Us
              </Button>
            </ContactLineWrapper>
            <ContactLineWrapper>
              <Heading level={3}>For wholesale inquiries:</Heading>
              <Button level={2} onClick={handleModalClick('Wholesale')}>
                Contact Us
              </Button>
            </ContactLineWrapper>
            <ContactLineWrapper>
              <Heading level={3}>For press inquiries:</Heading>
              <Button level={2} onClick={handleModalClick('Press')}>
                Contact Us
              </Button>
            </ContactLineWrapper> */}

            <ContactLineComponent
              contactLine={{ label: 'phone', contact: '+81 03 6206 0468' }}
            />
            <ContactLineWrapper>
              <Heading level={4}>visit</Heading>
              <Heading level={3}>
                <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x6018f32c218bac9f:0xff7fd6b0ff5c6cce?source=g.page.share">
                  KeepCup by JAU
                  <br />
                  合同会社 Long Forme
                  <br />
                  〒151-0063
                  <br />
                  東京都渋谷区富ヶ谷2-6-2
                  <br />
                  ヨヨギガーデンズ401
                </a>
              </Heading>
            </ContactLineWrapper>
          </ContactLines>

          {/* <ChatWrapper>
            <Chat />
          </ChatWrapper> */}
        </Wrapper>
      </PageWrapper>
    </>
  )
}
