import * as React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { Page, ShopifyCollection } from '../../types'
import { PageWrapper } from '../../components/Layout'
import { ContentBlock } from '../../components/ContentBlock'
import { Heading } from '../../components/Text'
import { Column } from '../../components/Layout'
import { RichText } from '../../components/RichText'
import { HeroBlock } from '../../components/ContentBlock/HeroBlock'
import { SEO } from '../../components/SEO'
import {
  definitely,
  getHeroImage,
  isValidHero,
  getFirstImage,
} from '../../utils'

const PageText = styled.div`
  margin-top: 10;
  h1,
  h2,
  h3 {
    text-align: center;
  }
`

interface PageViewProps {
  page: Page
  collections?: ShopifyCollection[]
}

const Grid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;

    ${theme.mediaQueries.mobile} {
      display: block;
    }
  `}
`

export const PageView = ({ page, collections }: PageViewProps) => {
  const {
    seo,
    content,
    title,
    hero,
    subtitle,
    hideTitle,
    fullWidth,
    slug,
    bodyRaw,
  } = page
  const defaultSeo = {
    title: title || '',
    image: getHeroImage(hero) || getFirstImage(bodyRaw),
  }
  if (!slug) throw new Error('No slug was fetched')
  const path = ['about', slug.current].join('/')
  return (
    <>
      <SEO seo={seo} defaultSeo={defaultSeo} path={path} />
      {hero && isValidHero(hero) ? <HeroBlock hero={hero} /> : null}
      <PageWrapper p={fullWidth ? '0' : undefined}>
        {hideTitle !== true ? (
          <>
            <Heading mt={8} textAlign="center" level={1}>
              {title}
            </Heading>
            {subtitle ? (
              <Heading textAlign="center" level={3} mb={8}>
                {subtitle}
              </Heading>
            ) : null}
          </>
        ) : null}
        <Grid>
          {definitely(content).map((c) => (
            <ContentBlock
              key={c._key || 'some-key'}
              content={c}
              collections={collections}
            />
          ))}
        </Grid>
        {/* <Column columnwidth="medium"> */}
        <PageText>
          <RichText article body={bodyRaw} />
        </PageText>
        {/* </Column> */}
      </PageWrapper>
    </>
  )
}
