import * as React from 'react'
import Link from 'next/link'
import styled, { css } from '@xstyled/styled-components'
import {
  ProductCollectionBlock as ProductCollectionBlockType,
  ShopifyCollection,
} from '../../types'
import { Heading } from '../Text'
import { RichText } from '../RichText'
import { ProductListing } from '../../views'

const ProductCollectionBlockStyled = styled.div`
  ${({ theme }) => css`
    padding: 8 0;
    grid-column: span 2;
    text-align: center;

    a {
      text-decoration: none;
    }

    ${theme.mediaQueries.mobile} {
      padding: 6 0 52px 0;
      overflow: hidden;
    }
  `}
`
interface ProductCollectionBlockProps {
  content: ProductCollectionBlockType
  collection?: ShopifyCollection
}

const CarouselContainer = styled.div`
  margin-top: 6;
`

/**
 * Procut collection Block
 */

export const ProductCollectionBlock = ({
  content,
  collection,
}: ProductCollectionBlockProps) => {
  const { title, subtitleRaw } = content

  const linkAs = collection ? `/collections/${collection.handle}` : ''
  return collection ? (
    <ProductCollectionBlockStyled>
      {collection ? (
        <Heading level={2}>
          <Link href="/collections/[collectionSlug]" as={linkAs}>
            <a>{title}</a>
          </Link>
        </Heading>
      ) : (
        <Heading level={2}>{title}</Heading>
      )}
      <RichText body={subtitleRaw} />
      <ProductListing collection={collection} />
    </ProductCollectionBlockStyled>
  ) : null
}
