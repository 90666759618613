import * as React from 'react'
import {
  ImageTextBlock as ImageTextBlockType,
  Hero,
  Carousel,
  ProductCollectionBlock as ProductCollectionBlockType,
  ShopifyCollection,
} from '../../types'
import { ImageTextBlock } from './ImageTextBlock'
import { HeroBlock } from './HeroBlock'
import { CarouselBlock } from './CarouselBlock'
import { ProductCollectionBlock } from './ProductCollectionBlock'

interface ContentBlockProps {
  content: ImageTextBlockType | Hero | Carousel | ProductCollectionBlockType
  collections?: ShopifyCollection[]
}

/**
 * determines the format of the content block and renders the appropriate component
 */

export const ContentBlock = ({ content, collections }: ContentBlockProps) => {
  switch (content.__typename) {
    case 'ImageTextBlock':
      return <ImageTextBlock content={content} />
    case 'Hero':
      return <HeroBlock hero={content} />
    case 'Carousel':
      return <CarouselBlock content={content} />
    case 'ProductCollectionBlock':
      const collection = collections?.find(
        (collection) => collection.handle === content.collection?.handle,
      )
      return (
        <ProductCollectionBlock content={content} collection={collection} />
      )
    default:
      // @ts-ignore
      console.warn(`No content block for type "${content.__typename}"`)
      return null
  }
}
